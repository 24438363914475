<template>
  <div v-if="invoiceImg.visible" class="invoice-img">
    <div v-clickoutside="handleImageClose" class="content">
      <el-image :src="invoiceImg.url">
      </el-image>
      <!--<el-carousel arrow="always" :autoplay="false">-->
      <!--<el-carousel-item v-for="item in invoiceImgs" :key="item">-->
      <!--<el-image :src="item" ></el-image>-->
      <!--</el-carousel-item>-->
      <!--</el-carousel>-->
    </div>
  </div>
</template>

<script>
import Clickoutside from 'element-ui/src/utils/clickoutside'

export default {
  name: 'InvoiceImg',
  directives: { Clickoutside },
  props: {
    invoiceImg: {
      type: Object,
      default: () => {
        return {
          visible: false,
          url: ''
        }
      }
    },
    invoiceImgs: []
  },
  mounted () {
    // console.log(this.invoiceImgs)
  },
  methods: {
    handleImageClose () {
      this.invoiceImg.visible = false
    }
  }
}
</script>

<style scoped lang="scss">
  /* 影像图片容器 */
  .invoice-img {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 5;

    .content {
      width: 50%;
      text-align: center;
    }

    .el-image {
      max-width: 680px;
      max-height: 680px;
    }
  }
</style>
