<template>
  <el-drawer append-to-body
    title="进项发票"
    :visible.sync="visible"
    :with-header="false"
    :show="show"
    size="789px"
    direction="rtl"
    @close="$emit('update:show', false)"
  >
    <div class="details-title">
      {{handleValueToLabel('InvoiceType',invoice.invoiceType)}}
    </div>
    <div class="details-content">
      <el-tabs v-model="active">
        <el-tab-pane label="发票详情" name="first">
          <div class="content-top">
            <el-row class="row-first">
              <el-col :span="8">
                <div class="verify-status">{{handleValueToLabel('InvoiceVerifyStatus',invoice.verifyStatus)}}</div>
              </el-col>
              <el-col :span="8">
                <label>查验日期：</label>
                <span>{{handleDateFormat(invoice.created)}}</span>
              </el-col>
              <el-col :span="8">
                <label>开票日期：</label>
                <span>{{handleDateFormat(invoice.invoiceTime)}}</span>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="8">
                <label>发票代码：</label>
                <span>{{invoice.invoiceCode}}</span>
              </el-col>
              <el-col :span="8">
                <label>发票号码：</label>
                <span>{{invoice.invoiceNo}}</span>
              </el-col>
              <el-col :span="8">
                <label>数电发票号码：</label>
                <span>{{invoice.eInvoiceNo}}</span>
              </el-col>
            </el-row>
          </div>
          <div class="content-middle">
            <div class="middle-average">
              <div class="first-row">
                <span>购买方</span>
              </div>
              <div class="second-row">
                <label style="min-width: 42px">名称:</label>
                <div>{{invoice.buyerName}}</div>
              </div>
              <div class="other-row">
                <label-span label="税号：" :span="invoice.buyerTaxNo"></label-span>
              </div>
              <div class="other-row">
                <label>地址电话:</label>
                <el-tooltip effect="dark" :content="invoice.buyerAddressTel" placement="top-start">
                  <span style="max-width: 18em;">{{invoice.buyerAddressTel}}</span>
                </el-tooltip>
              </div>
              <div class="other-row">
                <label style="min-width: 98px">开户行及账号：</label>
                <el-tooltip effect="dark" :content="invoice.buyerBankAccount" placement="top-start">
                  <span style="max-width: 17em;">{{invoice.buyerBankAccount}}</span>
                </el-tooltip>
              </div>
            </div>
            <div class="middle-average">
              <div class="first-row">
                <span>销售方</span>
              </div>
              <div class="second-row">
                <label style="min-width: 42px">名称:</label>
                <div>{{invoice.sellerName}}</div>
              </div>
              <div class="other-row">
                <label-span label="税号：" :span="invoice.sellerTaxNo"></label-span>
              </div>
              <div class="other-row">
                <label>地址电话:</label>
                <el-tooltip effect="dark" :content="invoice.sellerAddressTel" placement="top-start">
                  <span style="max-width: 18em;">{{invoice.sellerAddressTel}}</span>
                </el-tooltip>
              </div>
              <div class="other-row">
                <label style="min-width: 98px">开户行及账号：</label>
                <el-tooltip effect="dark" :content="invoice.sellerBankAccount" placement="top-start">
                  <span style="max-width: 17em;">{{invoice.sellerBankAccount}}</span>
                </el-tooltip>
              </div>
            </div>
          </div>
          <div class="content-bottom">
            <span>开票明细</span>
            <div class="bottom-table">
              <el-table
                :data="tableData"
                border
                stripe
                :span-method="handleArraySpanMethod"
                :header-cell-style="handleHeaderCellStyle"
                max-height="280"
                style="width:100%"
              >
                <el-table-column prop="goodName" label="货物或应税服务名称" show-overflow-tooltip/>
                <el-table-column prop="general" label="规格型号" show-overflow-tooltip/>
                <el-table-column prop="specifications" label="单位" show-overflow-tooltip/>
                <el-table-column prop="goodNum" label="数量" show-overflow-tooltip/>
                <el-table-column prop="goodPrice" label="单价" show-overflow-tooltip/>
                <el-table-column prop="amount" label="金额" show-overflow-tooltip/>
                <el-table-column prop="taxRate" label="税率" show-overflow-tooltip/>
                <el-table-column prop="taxAmount" label="税额" show-overflow-tooltip/>
              </el-table>
            </div>
          </div>
          <div class="content-remark">
            <div class="remark-title">备注</div>
            <div class="remark-content">
              <span v-html="invoice.remark" style="white-space: pre-line;"></span>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="单据详情" name="second">
          <el-row style="margin-top: 32px">
            <el-col :span="12">
              <label>报销单号：</label>
              <span>{{ reimbursement.reimburseNo }}</span>
            </el-col>
            <el-col :span="12">
              <label>提交人：</label>
              <span>{{ reimbursement.subject }}</span>
            </el-col>
          </el-row>
          <el-row style="margin-top: 12px">
            <el-col :span="12">
              <label>提交日期：</label>
              <span>{{ reimbursement.created }}</span>
            </el-col>
            <el-col :span="12">
              <label>单据状态：</label>
              <span>{{reimbursement.approvalStatus}}</span>
            </el-col>
          </el-row>
          <el-row style="margin-top: 12px">
            <el-col :span="12">
              <label>报销金额：</label>
              <span>{{ reimbursement.reimburseAmount }}</span>
            </el-col>
            <el-col :span="12">
              <label>费用标签：</label>
              <span>{{ reimbursement.itemName }}</span>
            </el-col>
          </el-row>
        </el-tab-pane>
      </el-tabs>
    </div>
  </el-drawer>
</template>

<script>
  import { tableStyle } from '@/util/mixins'

  export default {
    name: 'VatInvoiceDetail',
    mixins: [tableStyle],
    props: {
      show: {
        type: Boolean,
        default: false
      },
      invoice: {
        type: Object,
        default: () => {
        }
      }
    },
    data () {
      return {
        active: 'first',
        visible: this.show,
        reimbursement: {}
      }
    },
    watch: {
      show () {
        this.visible = this.show
      }
    },
    computed: {
      tableData () {
        return this.invoice.details
      }
    },
    methods: {
      /* 时间格式化 */
      handleDateFormat (date) {
        if (date == undefined) return ''
        return this.$moment(date).format('YYYY-MM-DD')
      },
      /* 合并单元格 */
      handleArraySpanMethod ({ rowIndex, columnIndex }) {
        const length = this.invoice.details.length
        if (rowIndex === length - 1) {
          if (columnIndex === 0) {
            return [1, 1]
          } else if (columnIndex === 1) {
            return [1, 4]
          } else {
            return [1, 3]
          }
        }
      }
    }
  }
</script>

<style scoped lang="scss">
  .details-title {
    font-size: 18px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    line-height: 24px;
    margin: 24px 24px 0 24px;
  }

  .details-content {
    margin: 8px 24px 24px 24px;

    .content-top {
      padding: 32px 0;
      border-bottom: 1px solid #CCCCCC;

      label {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 20px;
      }

      span {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 20px;
      }

      .verify-status {
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #09BB07;
        line-height: 20px;
      }
    }

    .content-middle {
      display: flex;
      margin-top: 32px;
      padding-bottom: 16px;
      border-bottom: 1px solid #CCCCCC;

      .middle-average {
        width: 400px;

        .first-row {
          margin-bottom: 16px;

          span {
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #333333;
            line-height: 20px;
          }
        }

        .second-row {
          display: flex;
          margin-bottom: 16px;

          label {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #666666;
            line-height: 20px;
            margin-right: 4px;
          }

          div {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            line-height: 20px;
            text-align: left;
            margin-right: 20px;
          }
        }

        .other-row {
          display: flex;
          align-items: center;
          margin-bottom: 16px;
          margin-right: 20px;

          label {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #666666;
            line-height: 20px;
            margin-right: 4px;
          }

          span {
            display: inline-block;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            line-height: 24px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
          }
        }
      }
    }

    .content-bottom {
      margin-top: 32px;
      height: calc(100% - 225px);
      border-bottom: 1px solid #CCCCCC;
      padding-bottom: 32px;

      span {
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 20px;
      }

      .bottom-table {
        margin-top: 16px;
      }
    }

    .content-remark {
      margin-top: 24px;

      .remark-title {
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 20px;
        margin-bottom: 16px;
      }

      .remark-content {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 20px;
        margin-bottom: 24px;
      }
    }
  }

  ::v-deep .el-drawer__body {
    overflow: auto;
  }

  ::v-deep .el-tabs__nav {
    margin: 0;
  }
</style>
