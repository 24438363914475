<template>
  <el-drawer append-to-body
    title="进项发票"
    :visible.sync="visible"
    :with-header="false"
    :show="show"
    size="789px"
    direction="rtl"
    @close="$emit('update:show', false)"
  >
    <div class="details-title">
      {{handleValueToLabel('InvoiceType',invoice.invoiceType)}}
    </div>
    <div class="details-content">
      <el-tabs v-model="active">
        <el-tab-pane label="发票详情" name="first">
          <el-row style="margin-top: 32px">
            <el-col :span="12">
              <label>乘车人：</label>
              <span>{{invoice.rider}}</span>
            </el-col>
            <el-col :span="12">
              <label>开票日期：</label>
              <span>{{handleDateFormat(invoice.rider)}}</span>
            </el-col>
          </el-row>
          <el-row style="margin-top: 12px">
            <el-col :span="12">
              <label>出发站：</label>
              <span>{{invoice.departure}}</span>
            </el-col>
            <el-col :span="12">
              <label>到达站：</label>
              <span>{{invoice.destination}}</span>
            </el-col>
          </el-row>
          <el-row style="margin-top: 12px">
            <el-col :span="12">
              <label>航班号：</label>
              <span>{{invoice.flight}}</span>
            </el-col>
            <el-col :span="12">
              <label>座位等级：</label>
              <span>{{invoice.seatClass}}</span>
            </el-col>
          </el-row>
          <el-row style="margin-top: 12px">
            <el-col :span="12">
              <label>电子客票号码：</label>
              <span>{{invoice.train21No}}</span>
            </el-col>
            <el-col :span="12">
              <label>票价（元）：</label>
              <span>{{invoice.amount}}</span>
            </el-col>
          </el-row>
          <el-row style="margin-top: 12px">
            <el-col :span="12">
              <label>燃油附加费（元）：</label>
              <span>{{invoice.surcharge}}</span>
            </el-col>
            <el-col :span="12">
              <label>合计票价（元）：</label>
              <span>{{invoice.amount + invoice.surcharge}}</span>
            </el-col>
          </el-row>
        </el-tab-pane>
        <el-tab-pane label="单据详情" name="second">
          <el-row style="margin-top: 32px">
            <el-col :span="12">
              <label>报销单号：</label>
              <span>{{ reimbursement.reimburseNo }}</span>
            </el-col>
            <el-col :span="12">
              <label>提交人：</label>
              <span>{{ reimbursement.subject }}</span>
            </el-col>
          </el-row>
          <el-row style="margin-top: 12px">
            <el-col :span="12">
              <label>提交日期：</label>
              <span>{{ reimbursement.created }}</span>
            </el-col>
            <el-col :span="12">
              <label>单据状态：</label>
              <span>{{reimbursement.approvalStatus}}</span>
            </el-col>
          </el-row>
          <el-row style="margin-top: 12px">
            <el-col :span="12">
              <label>报销金额：</label>
              <span>{{ reimbursement.reimburseAmount }}</span>
            </el-col>
            <el-col :span="12">
              <label>费用标签：</label>
              <span>{{ reimbursement.itemName }}</span>
            </el-col>
          </el-row>
          <el-row style="margin-top: 12px">
            <el-col :span="12">
              <label>数电发票号码：</label>
              <span>{{ reimbursement.eInvoiceNo }}</span>
            </el-col>
           
          </el-row>
        </el-tab-pane>
      </el-tabs>
    </div>
  </el-drawer>
</template>

<script>
  export default {
    name: 'OtherInvoiceDetail',
    props: {
      show: {
        type: Boolean,
        default:
          false
      },
      invoice: {
        type: Object,
        default: () => {
        }
      }
    },
    data () {
      return {
        active: 'first',
        visible: this.show,
        reimbursement: {}
      }
    },
    watch: {
      show () {
        this.visible = this.show
      }
    },
    methods: {
      /* 时间格式化 */
      handleDateFormat (date) {
        if (date == undefined || date == '') return ''
        return this.$moment(date).format('YYYY-MM-DD')
      },
    }
  }
</script>

<style scoped lang="scss">
  .details-title {
    font-size: 18px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    line-height: 24px;
    margin: 24px 24px 0 24px;
  }

  .details-content {
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    line-height: 20px;
    margin: 8px 24px 24px 24px;
  }

  ::v-deep .el-tabs__nav {
    margin: 0;
  }
</style>
